export type Keys = {
  napi: string;
  napiSecret: string;
  napiAppId: string;
};

export type ApiKeys = {
  browser: Keys;
  samsung: Keys;
  lg: Keys;
  firetv: Keys;
  xbox: Keys;
  steamdeck: Keys;
  chery: Keys;
  "lg-auto": Keys;
};

export type Config = {
  apiKeys: ApiKeys;
  napiBaseUri: string;
  baseUri: string;
  externalFolder: string;
  signupUrl: {
    samsung: string;
    lg: string;
    xbox: string;
    firetv: string;
    steamdeck: string;
    chery: string;
    "lg-auto": string;
  };
};

const environment: "local" | "development" | "stage" | "production" = (
  process.env.NODE_ENV === "development"
    ? "local"
    : process.env.REACT_APP_PLATFORM === "browser"
    ? "development"
    : process.env.REACT_APP_BRANCH_NAME || "local"
) as any;

const developmentKeys = {
  browser: {
    napi: "YjRmNjFhZDMtYjQ0YS00OTcyLTk2NTQtODg3YzE1ZDc3ZTQz",
    napiSecret: "ZDQxZTUxNDYtYzRhZi00ZTAwLThiZWMtY2I3N2RlZjE0MThi",
    napiAppId: "4167fd96-d76c-406b-a429-06b7d87e5013",
  },
  // tizen in bigscreen-core
  samsung: {
    napi: "ODA3MTMzYjctZTBiNi00NmI5LWJiYjMtZGM5NGIwNzRhNDc3",
    napiSecret: "OGIzNWI1ODUtNjE3Zi00M2IyLTk3NjgtOTQ3ODU2ZGJlMjc2",
    napiAppId: "b23d3201-f75f-44cb-b93c-90cb9eac6ab3",
  },
  // webos in bigscreen-core
  lg: {
    napi: "MzI5MWQyMDAtYmJjOS00NGQ1LWE4ZDktZWQ2YjVjODhlMTFl",
    napiSecret: "YTVmY2M5NjItYmI3My00MzcyLWFkZTAtOTIzMTRkNDI3MGY4",
    napiAppId: "9c818665-15a6-4602-b468-8a3d2345e157",
  },
  firetv: {
    napi: "MGIyN2MzZWYtMjkwYi00NDZjLTkyYmQtZGMwNWU5YmM4ZWIz",
    napiSecret: "YTI2MDQ4MjEtYmM0MC00MTQyLWI1NmItZTkzMTc5MTgzOGRm",
    napiAppId: "e2ca93a3-ce7b-41f5-84a5-5fe456e93ae3",
  },
  xbox: {
    napi: "ZTYwNGIxYzgtODIxZi00MTAyLTgzY2QtODY3YjRjYmFmOTFk",
    napiSecret: "OWY5MDAyYWUtNjA0My00YTJmLThkZGItMjIzZmYwYTg5NzYy",
    napiAppId: "89e65e12-ccf6-47cb-a432-a8bd53392476",
  },
  steamdeck: {
    napi: "ZGE4Y2M0Y2YtYTNiZS00YjE4LTg1NTktODRjMTFkNWI2MmNj",
    napiSecret: "MDY2MjExMTctZDNhMy00YTM0LWEzYWEtMmZiOTRhNGIwNGNk",
    napiAppId: "ce4476a1-0b93-4fb7-973a-b9fe0a5f017a",
  },
  chery: {
    napi: "Y2Y4OTUyMDctNmVkYy00MWZiLTg3YWYtODE5NGU5YmNiODc4",
    napiSecret: "ODFhYzNjMGItZWRhNC00ZmM5LWEyNWUtOWZkNDdlMzNjMWI4",
    napiAppId: "254a901c-464e-4ba5-82c7-10d490966a72",
  },
  "lg-auto": {
    napi: "NTcyM2Y3NmMtM2FlOC00NGU5LTgzMDQtMmRjNzZlZTQ1ODQ2",
    napiSecret: "MWEzMTljZTgtMzk4ZS00NWM4LTg4YWQtOGQ3ODM4ZTlkOWQz",
    napiAppId: "a6e7a476-073d-4fb4-a93a-2021fe82c152",
  },
};

const productionKeys = {
  browser: {
    napi: "YmM0ODE2OGItN2IzZS00ZWUwLTk3ZWEtYTgzY2E3MDlkNjE4",
    napiSecret: "ODJjMTZmYWQtMDMyNC00YTE2LTk2YTQtY2RlZGE4ZDk3ZGRl",
    napiAppId: "654ba55d-3cb1-42a2-8187-b9ada2c1a32a",
  },
  // tizen in bigscreen-core
  samsung: {
    napi: "NmY2OGY2NGMtZDY0My00OGU1LTk5YTQtNjNiYTJlNzMyMGI0",
    napiSecret: "MDNjZjEzZTQtOGFlOC00OGQ2LTljZTctMDFlMTQyYTZiZWJh",
    napiAppId: "ba53c9c1-e90e-4e46-b217-f758b7dcb133",
  },
  // webos in bigscreen-core
  lg: {
    napi: "OGFmZjgwZTQtZTYwYy00Y2Y4LWEyODItOGJhNWIxNTYxYzFi",
    napiSecret: "NzIxYmE2MTYtYWM2Zi00ZDZiLWJiODctMjEzZWUzZmFjYWRk",
    napiAppId: "644afb1c-002a-4b45-a48f-16ccb792bffa",
  },
  firetv: {
    napi: "YmZjMzQ3Y2UtMmJhNC00ODcxLTlhZTctMTQyMzRkNDc2NjI4",
    napiSecret: "YzEzZmEwY2EtM2M1Zi00NDZjLWFiMWYtYzkwNTQ0MGUyZDk5",
    napiAppId: "ba7cbf87-9dd8-440b-a0e4-03d5e34bc5a8",
  },
  xbox: {
    napi: "Y2Y3Yjg5NTktOGZiZi00NTZmLWFlNzgtYmY5OTNhYWY5ZGM3",
    napiSecret: "ODQ5Y2YzYTEtZTc2Zi00NTRmLWEyZTUtMTI2ODE3MzlhNGYz",
    napiAppId: "f58935a4-cc43-49bd-a2fc-1b7353cca657",
  },
  steamdeck: {
    napi: "MThkZTdiOTYtZjNjOC00ZWFhLTliZGQtNGJjMGY4OWY5NTg5",
    napiSecret: "MjY3MTM3NGItNGJiMi00ZjJkLWI3YWQtNzAxMjI2YWNiMzMw",
    napiAppId: "ad9656bc-dc1f-4097-95e5-bc1ef6de108d",
  },
  chery: {
    napi: "Y2Y4OTUyMDctNmVkYy00MWZiLTg3YWYtODE5NGU5YmNiODc4",
    napiSecret: "ODFhYzNjMGItZWRhNC00ZmM5LWEyNWUtOWZkNDdlMzNjMWI4",
    napiAppId: "254a901c-464e-4ba5-82c7-10d490966a72",
  },
  "lg-auto": {
    napi: "NDUzOTkxMTUtMmQ3Yy00MmYzLWI1NjMtYmU2MDM4Y2Q0YmRm",
    napiSecret: "ZDNiOTEzOWYtMzM3OC00YjFmLWFhN2UtZDcwNjUwMTA5OTMw",
    napiAppId: "5e3ee169-bfc6-4195-ac8f-904a75f42d45",
  },
};

const apikeys = {
  local: developmentKeys,
  development: developmentKeys,
  stage: productionKeys,
  production: productionKeys,
};

const napiBaseUri = {
  local: "https://api.napster.com/",
  development: "https://api.napster.com/",
  stage: "https://api.napster.com/",
  production: "https://api.napster.com/",
};

const baseUri = {
  local: "http://localhost:3001",
  development: "https://pair.dev.napster.com",
  stage: "https://pair.stg.napster.com",
  production: "https://pair.napster.com",
};

const externalFolder = {
  local: "http://localhost:5000",
  development: `${baseUri.development}/external`,
  stage: `${baseUri.stage}/external`,
  production: `${baseUri.production}/external`,
};

const config: Config = {
  napiBaseUri: napiBaseUri[environment],
  baseUri: baseUri[environment],
  apiKeys: apikeys[environment],
  externalFolder: externalFolder[environment],
  signupUrl: {
    samsung: "https://www.napster.com/samsung",
    lg: "https://www.napster.com/lg",
    firetv: "https://www.napster.com/firetv",
    xbox: "https://www.napster.com/xbox",
    steamdeck: "https://www.napster.com/steamdeck",
    chery: "https://www.napster.com/chery",
    "lg-auto": "https://www.napster.com/lg-auto",
  },
};

export default config;
